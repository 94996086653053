/* .image-carousel {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 400px;
  }
  
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0;
    transition: opacity 1s ease-in-out;
  }
  
  img.visible {
    opacity: 1;
  }
  
  img.hidden {
    opacity: 0;
  } */

  .fade-in {
    opacity: 0;
    animation: fadeIn 7.5s forwards;
  }
  
  
  
  @keyframes fadeIn {
    from {
      opacity: 0.1;
    }
    to {
      opacity: 1;
    }
  }
  
  

  .slide-in {
    opacity: 0.2;
    transform: translateY(460px);
    animation: slideIn 2s forwards, fadeIn 1.5s ease-in forwards;
  }
  
  @keyframes slideIn {
    from {
      opacity: 0.2;
      transform: translateY(460px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

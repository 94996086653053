.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.segeoFont{
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.mob-nav a{
  padding: 0.5rem 1rem;
  border-top-width: 1px;
}

.hover-blue-elements a:hover{
  color: #2563eb;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.segeoFont {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.App-link {
  color: #61dafb;
}

.text-7719AA{
  color: #7719AA;
}

.text-1D4ED8{
 color: #1D4ED8;
}

.btn-white button{
  border-color: white;
}

.events_hero_bg{
  background: url('./assets/events_bg1.jpg') center/cover no-repeat; /* Shorthand notation */
  /* height: 100%; */
  width: 100%;
}


.community_hero_bg{
  background: url('./assets/community_hero_bg.png') center/cover no-repeat; /* Shorthand notation */
  /* height: 100%; */
  width: 100%;
}


.createEvents_hero_bg{
  background: url('./assets/create_event_bg_img1.jpg') center/cover no-repeat; /* Shorthand notation */
  /* height: 100%; */
  width: 100%;
}
.create-event-bg-img{
  background: url('./assets/create_event_bg_img2.jpg') center/cover no-repeat; /* Shorthand notation */
  /* height: 100%; */
  width: 100%;
}
.create-event-bg-img1{
  background: url('./assets/create_event_bg_img3.jpg') center/cover no-repeat; /* Shorthand notation */
  /* height: 100%; */
  width: 100%;
}


.create_post_hero_bg{
  background: url('./assets/create_post_bg1.jpg') center/cover no-repeat; /* Shorthand notation */
  /* height: 100%; */
  width: 100%;
}
.create_post_bg1{
  background: url('./assets/create_post_bg2.jpg') center/cover no-repeat; /* Shorthand notation */
  /* height: 100%; */
  width: 100%;
}


.start_chap_bg2{
  background: url('./assets/start_chap_bg2.jpg') center/cover no-repeat; /* Shorthand notation */
  /* height: 100%; */
  width: 100%;
}


.create_program_hero_bg{
  background: url('./assets/create_program_hero_bg.jpg') center/cover no-repeat; /* Shorthand notation */
  /* height: 100%; */
  width: 100%;
}
.create_program_bg1{
  background: url('./assets/create_program_bg1.jpg') center/cover no-repeat; /* Shorthand notation */
  /* height: 100%; */
  width: 100%;
}
.create_program_bg2{
  background: url('./assets/create_program_bg2.jpg') center/cover no-repeat; /* Shorthand notation */
  /* height: 100%; */
  width: 100%;
}


.chapter_hero_bg{
  background: url('./assets/chapter_hero_bg.jpg') center/cover no-repeat; /* Shorthand notation */
  /* height: 100%; */
  width: 100%;
}


.club_hero_bg{
  background: url('./assets/club_hero_bg.png') center/cover no-repeat; /* Shorthand notation */
  /* height: 100%; */
  width: 100%;
}


.brGr1{
  border-left: 4px solid;
  border-image: linear-gradient(to bottom, #F6393A, #D908FA) 1;
}
.brGr2{
  border-left: 4px solid;
  border-image: linear-gradient(to bottom, #7EE5FC, #3F34FB) 1;
}
.brGr3{
  border-left: 4px solid;
  border-image: linear-gradient(to bottom, #71D4FF, #04D1A9) 1;
}
.brGr4{
  border-left: 4px solid;
  border-image: linear-gradient(to bottom, #0E75ED, #1EC9EE) 1;
}




@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



